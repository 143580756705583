import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Spinner } from "react-bootstrap";

const LoadingPopup = ({viewLoading}) => {
    const [show, setShow] = useState(false);
    
    useEffect(()=>{
        setShow(viewLoading)
    },[viewLoading])

    return (
        <>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-60w"
                centered
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Body>
                    <div className="card p-5">
                            <div className="text-center">
                                <Spinner animation="border" variant="primary" />
                            </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default LoadingPopup;
