import React, { useEffect, useState } from "react";
import SidebarComponent from "../../Components/Shared/SidebarComponent";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { savingsCollectionRef } from "../../firebase/fire-app";
import { currencyFormatter, timeStampToDate } from "../../utilities";

const CompSavingsList = () => {
    const [allTransaction, loading, error] = useCollectionData(
        savingsCollectionRef.orderBy("date", "desc"),
        { idField: "id" }
    );

    return (
        <>
            <div className="container">
            {allTransaction &&
                                allTransaction.length > 0 &&
                                allTransaction.map((item) => (
                                    <TransactionComponent
                                        data={item}
                                        key={item.id}
                                    />
                                ))}
            </div>
        </>
    );
};

export default CompSavingsList;


const TransactionComponent = ({ data }) => {
    return (
        <>
                <div className="card trx-card outline-primary bg-light-primary p-3">
                    <div className="d-flex justify-content-between">
                        <p>Amount: <span className="font-bold">{currencyFormatter.format(data.amount)}</span></p>
                        <p>Type: {data.spendType}</p>
                    </div>
                    <div className="d-flex justify-content-between">
                        <p>{timeStampToDate(data.date)}</p>
                        <p>Note: {data.note}</p>
                    </div>

                </div>
            

        </>
    );
};
