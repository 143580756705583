import React, { useState } from "react";
import SidebarComponent from '../../Components/Shared/SidebarComponent';
import { dataCollectionRef, transactionsCollectionRef } from "../../firebase/fire-app";
import firebase from "firebase";
import InputWrapper from "../../Components/FormComponents/InputWrapper";
import { useForm } from "react-hook-form";
import { MandatoryAsterisk } from "../../Components/FormElements";
import { transactionMethods } from "./transactionMethods";
import PopupCreditConfirmation from "./PopupCreditConfirmation";
import LoadingPopup from "../../Components/Shared/LoadingPopup";

const ViewCredit = () => {
    const { register, handleSubmit, watch, errors } = useForm();

    const [amount, setAmount] = useState(0);
    const [category, setCategory] = useState("");
    const [paymentMethod, setPaymentMethod] = useState("cash")
    const [note, setNote] = useState("")
    const [loading, setLoading] = useState(false)
    const [viewTransactionData, setViewTransactionData]= useState(null)


    const onPaymentMethodChange = (e) => {
        setPaymentMethod(e.target.value)
    }
    const onTrxSubmit = (data, e) => {

        setLoading(true);
        const trxData = {
            date: new Date(),
            amount: Number(data.amount),
            flow: 'in',
            paymentMethod: data.paymentMethod,
            note: data.note

        }
        // alert(JSON.stringify(trxData))
        transactionsCollectionRef.add(trxData)
            .then(() => {
                dataCollectionRef.doc('balance').update(
                    {
                        [trxData.paymentMethod]: firebase.firestore.FieldValue.increment(trxData.amount),
                        totalCurrentBalance: firebase.firestore.FieldValue.increment(trxData.amount),
                        lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
                    }
                ).then(() => {
                    e.target.reset()
                    setLoading(false)
                    setViewTransactionData(trxData)

                })
            })

    }
    return (
        <>
          <LoadingPopup viewLoading={loading}/>
        {!loading && viewTransactionData && <PopupCreditConfirmation viewPopup={true} transaction={viewTransactionData}/>}
            <SidebarComponent>
                <section>
                    <div className="container">
                        <div className="p-0 p-mb-5">
                            <h4 className="text-center mb-3 font-bold">Credit Account</h4>
                            <form onSubmit={handleSubmit(onTrxSubmit)} className="row">
                                <InputWrapper mandatory col="5" label="Amount">
                                    <input {...register("amount", { required: true })} type="number" name="amount" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                </InputWrapper>
                                <div class="mb-3 col-md-5">
                                    <label for="exampleInputEmail1" class="form-label font-bold">Payment Method <MandatoryAsterisk /></label>

                                    {transactionMethods.map((item) => <div class="form-check">
                                        <input {...register("paymentMethod")} class="form-check-input" value={item} type="radio" name="paymentMethod" id={item} />
                                        <label class="form-check-label" for={item}>
                                            {item}
                                        </label>
                                    </div>)}
                                </div>
                                <div class="mb-3 col-md-5">
                                    <label for="exampleInputEmail1" class="form-label font-bold">Note</label>

                                    <textarea {...register("note")} className="form-control" name="note" id="" cols="30" rows="2"></textarea>
                                </div>
                                <div className="col-12">
                                    <button type="submit" className="btn btn-primary">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </SidebarComponent>
        </>
    );
};

export default ViewCredit;
