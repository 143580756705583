import React, { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import SidebarComponent from "../../Components/Shared/SidebarComponent";
import CompSavingsEntry from "./CompSavingsEntry";
import CompSavingsList from "./CompSavingsList";

const ViewSavings = () => {
    const [key, setKey] = useState('entry');

    return (
        <>
        <SidebarComponent>
            <Tabs
                defaultActiveKey="profile"
                id="uncontrolled-tab-example"
                className="mb-3 mt-3"
                activeKey={key}
                onSelect={(k) => setKey(k)}
            >
                <Tab eventKey="entry"  title="Savings Entry">
                   <CompSavingsEntry/>
                </Tab>
                <Tab eventKey="list" title="Savings List">
                    <CompSavingsList/>
                </Tab>
              
            </Tabs>
            </SidebarComponent>
        </>
    );
};

export default ViewSavings;
