import { FaCashRegister, FaFileAlt, FaMinus, FaPlus } from "react-icons/fa";
import { TfiLoop } from "react-icons/tfi";

const dashboardMenu = [
    {
        id: 2,
        text: `Debit`,
        href: `/`,
        icon: <FaMinus/>
    },
    {
        id: 1,
        text: `Credit`,
        href: `/credit`,
        icon: <FaPlus/>
    },
    
    {
        id: 3,
        text: `Transactions`,
        href: `/transactions`,
        icon: <FaFileAlt/>
    },
    {
        id: 3,
        text: `Transfer`,
        href: `/transfer`,
        icon: <TfiLoop/>
    },
    {
        id: 4,
        text: `Dashboard`,
        href: `/dashboard`,
        icon: <FaCashRegister/>
    },
   
    
]


export default dashboardMenu;