import React, { useState, useContext, useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import fireApp, {
    authUsersCollection,
    userProfilesCollection,
} from "../firebase/fire-app";
import firebase from "firebase/app";
import "firebase/auth";

export const AuthContext = React.createContext();
export const AuthContextProvider = ({ children }) => {
    const authManager = Auth();

    return (
        <AuthContext.Provider value={authManager}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);

const Auth = () => {
    const [currentUser, setCurrentUser] = useState(null);
    const [loadingUser, setLoadingUser] = useState(false);
    const [hasAccess, setHasAccess] = useState(false);
    const [salesData, setSalesData] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [showSidebar, setShowSidebar] = useState(false);
    const [allAuthUsers, setAllAuthUsers] = useState([]);

    const handleGoogleSignIn = () => {
        var provider = new firebase.auth.GoogleAuthProvider();
        return firebase
            .auth()
            .signInWithPopup(provider)
            .then((result) => {
                const fUser = result.user;
                const userDetails = {
                    id: fUser.uid,
                    name: fUser.displayName,
                    email: fUser.email,
                    photo: fUser.photoURL,
                };
                const loggedEmail = result.user.email;
                userProfilesCollection
                    .doc(loggedEmail)
                    .get()
                    .then((doc) => {
                        if (doc.exists) {
                            const data = doc.data();
                            setCurrentUser(data);
                            return data;
                        } else {
                            setCurrentUser(null);
                        }
                    });
            })
            .catch(function (error) {
                const errorMessage = error.message;
                alert(errorMessage);
            });
    };

    useEffect(() => {
        fireApp.auth().onAuthStateChanged((result) => {
            if (result) {
                const loggedEmail = result.email;
                userProfilesCollection
                    .doc(loggedEmail)
                    .get()
                    .then((doc) => {
                        if (doc.exists) {
                            // alert("exisss")
                            const data = doc.data();
                            setCurrentUser(data);
                            return data;
                        } else {
                            setCurrentUser(null);
                        }
                    });
            }
        });
    }, []);

    const signOut = () => {
        return fireApp
            .auth()
            .signOut()
            .then(() => {
                setCurrentUser(null);
            });
    };

    return {
        allAuthUsers,
        setAllAuthUsers,
        showSidebar,
        setShowSidebar,
        isAdmin,
        setIsAdmin,
        currentUser,
        setCurrentUser,
        handleGoogleSignIn,
        signOut,
        hasAccess,
        salesData,
        setHasAccess
    };
};
