import React, { useState } from "react";
import SidebarComponent from "../../Components/Shared/SidebarComponent";
import {
    dataCollectionRef,
    savingsCollectionRef,
    spendTypeCollectionRef,
    transactionsCollectionRef,
} from "../../firebase/fire-app";
import firebase from "firebase";
import InputWrapper from "../../Components/FormComponents/InputWrapper";
import { useForm } from "react-hook-form";
import { MandatoryAsterisk } from "../../Components/FormElements";
import { spendType, transactionMethods } from "./transactionMethods";
import LoadingPopup from "../../Components/Shared/LoadingPopup";
import PopupDebitConfirmation from "./PopupDebitConfirmation";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { customMonthName } from "../../utilities";
import PopupSavingConfirmation from "./PopupSavingConfirmation";

const CompSavingsEntry = () => {
    const [spendTypes, loadingTypes, error] = useCollectionData(
        spendTypeCollectionRef.where("hide", "==", false).orderBy("name"),
        { idField: "id" }
    );

    spendTypeCollectionRef.where("hide", "==", false).orderBy("name").get();

    const { register, handleSubmit, watch, errors } = useForm();
    const [viewTransactionData, setViewTransactionData] = useState(null);
    const [loading, setLoading] = useState(false);
    const onTrxSubmit = (data, e) => {
        setLoading(true);
        const trxData = {
            date: new Date(),
            amount: Number(data.amount),
            month: customMonthName(new Date()),
            spendType: data.spendType,
            note: data.note,
            // lending: data.lendingTrx
        };

        savingsCollectionRef.add(trxData).then(() => {
            dataCollectionRef
                .doc("balance")
                .update({
                    saving: firebase.firestore.FieldValue.increment(
                        trxData.amount
                    ),
                    lastUpdated:
                        firebase.firestore.FieldValue.serverTimestamp(),
                })
                .then(() => {
                    e.target.reset();
                    setLoading(false);
                    setViewTransactionData(trxData);
                });
        });
    };

    return (
        <>
            <LoadingPopup viewLoading={loading} />
            {!loading && viewTransactionData && (
                <PopupSavingConfirmation
                    viewPopup={true}
                    transaction={viewTransactionData}
                />
            )}
            <section>
                <div className="container">
                    <div className="p-0 p-mb-5">
                        {/* <h4 className="text-center mb-3 font-bold">Savings</h4> */}
                        <form
                            onSubmit={handleSubmit(onTrxSubmit)}
                            className="row"
                        >
                            <InputWrapper mandatory col="5" label="Amount">
                                <input
                                    {...register("amount", { required: true })}
                                    type="number"
                                    name="amount"
                                    class="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                />
                            </InputWrapper>

                            <div className="col-6">
                                <label
                                    for="exampleInputEmail1"
                                    class="form-label font-bold"
                                >
                                    Spend type <MandatoryAsterisk />
                                </label>
                                {spendTypes &&
                                    spendTypes.length > 0 &&
                                    spendTypes.map((item) => (
                                        <div class="form-check">
                                            <input
                                                {...register("spendType")}
                                                class="form-check-input"
                                                value={item.name}
                                                type="radio"
                                                name="spendType"
                                                id={item.name}
                                            />
                                            <label
                                                class="form-check-label"
                                                for={item.name}
                                            >
                                                {item.name}
                                            </label>
                                        </div>
                                    ))}
                            </div>
                            {/* <div className="col-12 mb-3">
                                    <label for="exampleInputEmail1" class="form-label font-bold">Lending? <MandatoryAsterisk /></label>
                                    <div className="d-flex">
                                        <div class="form-check me-4">
                                            <input {...register("lendingTrx")} checked class="form-check-input" value="no" type="radio" name="lendingTrx" id="lendingNo" />
                                            <label class="form-check-label" for="lendingNo">
                                                No
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input {...register("lendingTrx")} class="form-check-input" value="yes" type="radio" name="lendingTrx" id="lendingYes" />
                                            <label class="form-check-label" for="lendingYes">
                                                Yes
                                            </label>
                                        </div>
                                    </div>
                                </div> */}

                            <div class="mb-3 col-md-5">
                                <label
                                    for="exampleInputEmail1"
                                    class="form-label font-bold"
                                >
                                    Note
                                </label>

                                <textarea
                                    {...register("note")}
                                    className="form-control"
                                    name="note"
                                    id=""
                                    cols="30"
                                    rows="2"
                                ></textarea>
                            </div>
                            <div className="col-12">
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
};

export default CompSavingsEntry;
