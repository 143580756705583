export const timeStampToDate = (timeStamp) => {
    if (timeStamp) {
        const milliseconds = timeStamp.seconds * 1000;
        const tt = new Date(milliseconds);
        const dateString = tt.toDateString();
        return dateString;
    }
};

export const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "BDT",
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const customDateFormat = (utcDate) => {
    if (utcDate) {
        let today = new Date(utcDate.seconds * 1000);
        var dd = today.getDate();

        var mm = today.getMonth() + 1;
        var yyyy = today.getFullYear();
        if (dd < 10) {
            dd = "0" + dd;
        }

        if (mm < 10) {
            mm = "0" + mm;
        }
        // today = mm + '-' + dd + '-' + yyyy;
        // console.log(today);
        // today = mm + '/' + dd + '/' + yyyy;
        // console.log(today);
        // today = dd + '-' + mm + '-' + yyyy;
        // console.log(today);
        today = dd + "/" + mm + "/" + yyyy;
        return today;
    } else {
        return "Not yet";
    }
};

export const screenSizePickerDateRange = () => {
    const width = window.innerWidth;
    if (width < 700) {
        return "vertical";
    } else {
        return "horizontal";
    }
};

export const removeFromArray = (array, value) => {
    const itemIndex = array.indexOf(value);
    if (itemIndex > -1) {
        // Remove the item from the array
        array.splice(itemIndex, 1);
        return array;
    } else {
        return array;
    }
};

export const numberWithCommas = (x) => {
    if (x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
};


export function customMonthName(date) {
    const monthNames = [
        "JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE",
        "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER"
    ];

    let month = monthNames[date.getMonth()];  // Get the month name
    let year = date.getFullYear().toString().slice(-2);  // Get last two digits of the year

    return month + year;  // Combine month and year
}