import React, { useState } from "react";
import SidebarComponent from "../../Components/Shared/SidebarComponent";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { spendTypeCollectionRef } from "../../firebase/fire-app";
import "./ViewConfiguration.scss"
import PopupEditSpendType from "./PopupEditSpendType";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import PopupAddSpendType from "./PopupAddSpendType";
import { FaTrashAlt } from "react-icons/fa";

const ViewConfiguration = () => {
    const [spendtypes, loading, error] = useCollectionData(
        spendTypeCollectionRef,
        { idField: "id" }
    );
    return (
        <>
            <SidebarComponent>
                <section>
                    <div className="container">
                        <div className="p-0 p-mb-5">
                            <h4 className="text-center mb-3 font-bold">
                                Spend Types
                            </h4>
                            <div className="row">
                                {spendtypes &&
                                    spendtypes.length > 0 &&
                                    spendtypes.map((item) => (
                                        <SpendTypeComponent
                                            data={item}
                                            key={item.id}
                                        />
                                    ))}
                            </div>
                            <div className="text-center">
                            <PopupAddSpendType/>

                            </div>
                        </div>
                    </div>
                </section>
            </SidebarComponent>
        </>
    );
};

export default ViewConfiguration;

const SpendTypeComponent = ({ data }) => {

    const onVisibilityChange =()=>{
        spendTypeCollectionRef.doc(data.id).update({
            hide: !data.hide
        })
    }
    const onDeleteItem =()=>{
        spendTypeCollectionRef.doc(data.id).delete()
    }
    return (
        <div className="col-md-6 mb-2">
            <div className={`type-card p-2 d-flex justify-content-between align-items-center ${data.hide? "border-hide": "border-primary"}`}>
                <span className="font-14 font-bold">{data.name}</span>
                
                {data.hide ? <FaEyeSlash onClick={onVisibilityChange} className="font-18 ms-auto me-4 font-bold"/> : <FaEye onClick={onVisibilityChange} className="font-18 text-primary ms-auto me-4 font-bold"/> }
<FaTrashAlt className="text-danger font-18 me-4" onClick={onDeleteItem}/>
                {data && <PopupEditSpendType data={data}/>}

            </div>
        </div>
    );
};
