import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { MdEdit } from "react-icons/md";
import InputWrapper from "../../Components/FormComponents/InputWrapper";
import { spendTypeCollectionRef } from "../../firebase/fire-app";

const PopupAddSpendType = () => {
    const [show, setShow] = useState(false);
    const [typeName, setTypeName] = useState();

    const onSave = () => {
        spendTypeCollectionRef
            .add({
                name: typeName,
                hide: false
            })
            .then(() => {
                setShow(false);
            });
    };
    return (
        <>
            <button
                onClick={() => setShow(true)}
                className="btn btn-sm btn-primary "
            >
                Add new
            </button>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-60w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header className="py-1 " closeButton>
                    <h5
                        className="font-weight-bold mt-1"
                        id="example-custom-modal-styling-title"
                    >
                      Add new spend type
                    </h5>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <InputWrapper mandatory col="8" label="name">
                            <input
                                onBlur={(e) => setTypeName(e.target.value)}
                                type="text"
                                name="name"
                                className="form-control"
                            />
                        </InputWrapper>
                        <div className="col-4">
                            <button
                                onClick={onSave}
                                className="btn btn-sm btn-primary"
                            >
                                  Save
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default PopupAddSpendType;
