import React from 'react';
import SidebarComponent from '../../Components/Shared/SidebarComponent';

const ViewDevelopmentOptions = () => {
    return (
        <>
            <SidebarComponent>
                <section>
                    <div className="container">
                        <div className="p-0 p-mb-5">
                        <h4 className="text-center mb-3 font-bold">Development Areas</h4>

                        </div>
                    </div>
                </section>
            </SidebarComponent>
        </>
    );
};

export default ViewDevelopmentOptions;