import React, { useEffect, useState } from "react";
import "./sidebar.scss";
import { GoHome } from "react-icons/go";
import { FaHome } from "react-icons/fa";
import dashboardMenu from "../../Views/ViewDashboard.js/DataDashboardMenu";
import { Link, useLocation } from "react-router-dom";
import adminSidebarMenus from "../../Views/ViewDashboard.js/DataAdminMenus";
import { useAuth } from "../../Hooks/useAuth";
import SectionLoading from "../SectionLoading";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "./responsive.scss";
import { BsArrowLeft } from "react-icons/bs";
import { HiMenuAlt1 } from "react-icons/hi";
import BottomNavigation from "../BottomNavigation/BottomNavigation";
import { GoGear } from "react-icons/go";
import { FaRegLightbulb } from "react-icons/fa";
import { BsBank2 } from "react-icons/bs";

const SidebarComponent = ({ children }) => {
    const { currentUser, isAdmin, setIsAdmin, showSidebar, setShowSidebar } =
        useAuth();
    const onLogOut = () => {
        firebase
            .auth()
            .signOut()
            .then((res) => {
                sessionStorage.removeItem("token");
                setIsAdmin(false);
                window.location.href = "/";
            })
            .catch((err) => {
                alert(err);
            });
    };
    // onLogOut()
    const [sidebarActiveClass, setSidebarActiveClass] = useState(false);

    const onToggleSidebar = () => {
        setSidebarActiveClass(!sidebarActiveClass);
    };
    const configurationMenu = {
        id: 5,
        text: `Configuration`,
        href: "/system-configuration",
        icon: <GoGear/>
    }
    const developmentMenu = {
        id: 5,
        text: `Dev Areas`,
        href: "/development-areas",
        icon: <FaRegLightbulb/>
    }
    const savingsMenu = {
        id: 5,
        text: `Savings`,
        href: "/savings",
        icon: <BsBank2/>
    }

    return (
        <>
            <button
                onClick={onToggleSidebar}
                className="btn font-30 px-2 py-2 light-shadow btn-primary btn-customized open-menu"
            >
                <i class="fas fa-align-left"></i>{" "}
                <span>
                    <HiMenuAlt1 />
                </span>
            </button>

            {currentUser ? (
                <main className="d-flex flex-nowrap">
                    <div
                        className={` p-3 bg-primary sidebar-container ${
                            sidebarActiveClass && "active"
                        }`}
                    >
                        <div className="d-flex justify-content-between">
                            <a
                                href="/"
                                className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none"
                            >
                                <span className="bi pe-none me-2">
                                    {/* <GoHome/> */}
                                </span>
                                <span className="fs-4 font-weight-bold font-bold">
                                   Swajannn
                                </span>
                            </a>
                            <button
                                onClick={onToggleSidebar}
                                className="close-menu light-shadow d-md-none btn-sm px-3 py-0 btn-white btn"
                            >
                                <BsArrowLeft />
                            </button>
                        </div>
                        <hr />
                        <ul className="nav nav-pills flex-column mb-auto">
                            {dashboardMenu.map((item) => (
                                <SidebarItem key={item.key} data={item} />
                            ))}
                            <SidebarItem data={savingsMenu}/>
                            <SidebarItem data={configurationMenu}/>
                            <SidebarItem data={developmentMenu}/>
                        </ul>
                        <button
                            onClick={onLogOut}
                            className="btn btn-sm btn-white"
                        >
                            Logout: {currentUser.name}
                        </button>
                    </div>

                    <div className="b-example-divider b-example-vr"></div>
                    <div className="site-content pt-5 pt-md-0">
                        {children}
                        <BottomNavigation />
                    </div>
                </main>
            ) : (
                <SectionLoading />
            )}
        </>
    );
};

export default SidebarComponent;

const SidebarItem = ({ data }) => {
    const location = useLocation();
    const [activeMenu, setActiveMenu] = useState(false);

    useEffect(() => {
        if (location.pathname === data.href) {
            setActiveMenu(true);
        } else {
            setActiveMenu(false);
        }
    }, [location]);

    return (
        <li className="nav-item mb-2    ">
            <Link
                to={`${data.href}`}
                className={`nav-link font-bold ${
                    activeMenu && "active"
                } d-flex align-items-center`}
                aria-current="page"
            >
                <span className="bi pe-none me-2">
                    {data.icon}
                </span>
                {data.text}
            </Link>
        </li>
    );
};
