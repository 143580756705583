import firebase from "firebase";
import "firebase/auth";
import firebaseConfig from "../firebase-config";

const fireApp = firebase.initializeApp(
    {
        apiKey: "AIzaSyBz9Skclgj6sAqf2Un8rcmgKbz7Wuoxw6I",
        authDomain: "personal-finance-sjn.firebaseapp.com",
        projectId: "personal-finance-sjn",
        storageBucket: "personal-finance-sjn.appspot.com",
        messagingSenderId: "745605896795",
        appId: "1:745605896795:web:ea09e80e38d8c41f8c7d7e",
        measurementId: "G-XWHZSL3QTT"
      }
);

fireApp
    .firestore()
    .enablePersistence()
    .catch((err) => {
        if (err.code === "failed-precondition") {
            console.log(err);
        } else console.log(err);
    });



export default fireApp;
export const fireStoreDB = fireApp.firestore();
export const fireDatabase = fireApp.database();
export const fireStorage = fireApp.storage();


export const authUsersCollection = fireStoreDB
    .collection("authUsers");

export const spendTypeCollectionRef = fireStoreDB.collection("spendType")
export const userProfilesCollection = fireStoreDB
    .collection("userProfiles");
    export const transactionsCollectionRef = fireStoreDB.collection("transactions")
    export const balanceHistoryCollectionRef = fireStoreDB.collection("balanceHistory")
    export const dataCollectionRef = fireStoreDB.collection("data")
    export const savingsCollectionRef = fireStoreDB.collection("savings")

