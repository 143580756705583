import DashboardLayout from "./Components/DashboardLayout/DashboardLayout";
import { AuthContextProvider } from "./Hooks/useAuth";
// import SidebarComponent from "./Components/Shared/SidebarComponent"
import "./styles/bootstrap.scss"
import Main from "./Views/Main";

import "./reset.scss"


function App() {
  return (
    <>
      <AuthContextProvider>

        <Main />
      </AuthContextProvider>
    </>
  );
}

export default App;
