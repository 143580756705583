import React, { useState } from 'react';
import SidebarComponent from '../../Components/Shared/SidebarComponent';
import { useForm } from 'react-hook-form';
import { dataCollectionRef, transactionsCollectionRef } from '../../firebase/fire-app';
import firebase from "firebase";
import InputWrapper from "../../Components/FormComponents/InputWrapper";
import { MandatoryAsterisk } from '../../Components/FormElements';
import { spendType, transactionMethods } from '../TransactionViews/transactionMethods';
import LoadingPopup from '../../Components/Shared/LoadingPopup';
import PopupTransferConfirmation from './PopupTransferConfirmation';

const ViewTransfer = ({balanceDetails}) => {
    const { register, handleSubmit, watch, errors } = useForm();
    const [loading, setLoading] = useState(false)
    const [viewTransactionData, setViewTransactionData]= useState(null)


    const onTrxSubmit = (data, e) => {
        setLoading(true);
        const trxData = {
            date: new Date(),
            amount: Number(data.amount),
            flow: 'transfer',
            paymentMethod: data.paymentMethod,
            spendType: "Transfer",
            note: data.note,
            transferredTo: data.transferredTo,
            ...balanceDetails
            // lending: data.lendingTrx
        }


        transactionsCollectionRef.add(trxData)
            .then(() => {
                dataCollectionRef.doc('balance').update(
                    {
                        [trxData.paymentMethod]: firebase.firestore.FieldValue.increment(trxData.amount * (-1)),
                        [trxData.transferredTo]: firebase.firestore.FieldValue.increment(trxData.amount ),
                        lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
                    }
                ).then(() => {
                    e.target.reset()
                    setLoading(false)
                    setViewTransactionData(trxData)
                })
            })

    }
    return (
        <>
                <LoadingPopup viewLoading={loading}/>
        {!loading && viewTransactionData && <PopupTransferConfirmation viewPopup={true} transaction={viewTransactionData}/>}

            <SidebarComponent>
                <section>
                    <div className="container">
                        <div className="p-0 p-mb-5">
                        <h4 className="text-center mb-3 font-bold">Transfer</h4>
                        <form onSubmit={handleSubmit(onTrxSubmit)} className="row">
                                <InputWrapper mandatory col="5" label="Amount">
                                    <input {...register("amount", { required: true })} type="number" name="amount" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                </InputWrapper>
                                <div className="col-6">
                                    <div className="row">
                                        <div class="mb-3">
                                            <label for="exampleInputEmail1" class="form-label font-bold">From <MandatoryAsterisk /></label>
                                            {transactionMethods.map((item) => <div class="form-check">
                                                <input {...register("paymentMethod")} class="form-check-input" value={item} type="radio" name="paymentMethod" id={item} />
                                                <label class="form-check-label" for={item}>
                                                    {item}
                                                </label>
                                            </div>)}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="row">
                                        <div class="mb-3">
                                            <label for="exampleInputEmail1" class="form-label font-bold">To <MandatoryAsterisk /></label>
                                            {transactionMethods.map((item) => <div class="form-check">
                                                <input {...register("transferredTo")} className="form-check-input" value={item} type="radio" name="transferredTo" id={item} />
                                                <label class="form-check-label" for={item}>
                                                    {item}
                                                </label>
                                            </div>)}
                                        </div>
                                    </div>
                                </div>
                               
                                {/* <div className="col-12 mb-3">
                                    <label for="exampleInputEmail1" class="form-label font-bold">Lending? <MandatoryAsterisk /></label>
                                    <div className="d-flex">
                                        <div class="form-check me-4">
                                            <input {...register("lendingTrx")} checked class="form-check-input" value="no" type="radio" name="lendingTrx" id="lendingNo" />
                                            <label class="form-check-label" for="lendingNo">
                                                No
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input {...register("lendingTrx")} class="form-check-input" value="yes" type="radio" name="lendingTrx" id="lendingYes" />
                                            <label class="form-check-label" for="lendingYes">
                                                Yes
                                            </label>
                                        </div>
                                    </div>
                                </div> */}

                                <div class="mb-3 col-md-5">
                                    <label for="exampleInputEmail1" class="form-label font-bold">Note</label>

                                    <textarea {...register("note")} className="form-control" name="note" id="" cols="30" rows="2"></textarea>
                                </div>
                                <div className="col-12">
                                    <button type="submit" className="btn btn-primary">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </SidebarComponent>
        </>
    );
};

export default ViewTransfer;