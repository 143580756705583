export const MandatoryAsterisk =()=>{
    return(
        <span className='text-danger font-weight-bold'>*</span>
    )
}

export const StatusBadge = ({ status }) => {
    return(
        <span className={`badge ${status === 'Pending' ? 'bg-warning' : status === 'Approved' ? 'bg-success' : 'bg-danger'} text-white`}>{status}</span>
    )
}