import React, { useEffect, useState } from 'react';
import "./bottomnavigation.scss"
import { Link, useLocation } from 'react-router-dom';
import dashboardMenu from '../../Views/ViewDashboard.js/DataDashboardMenu';


const BottomNavigation = () => {
    return (
        <>
            <div className="bottom-navigation">

                <div class="navbar p-0 d-flex justify-content-center" id="myNavbar">
                    {dashboardMenu.map(item => <BottomItem key={item.id} data={item} />)}
                </div>

            </div>
        </>
    );
};

export default BottomNavigation;



const BottomItem = ({ data }) => {
    const location = useLocation();
    const [activeMenu, setActiveMenu] = useState(false);

    useEffect(() => {
        if (location.pathname === data.href) {
            setActiveMenu(true)
        } else {
            setActiveMenu(false)
        }
    }, [location])

    return (

            <Link to={`${data.href}`} className={`${activeMenu && "active"} `}>
                {data.icon}
            </Link>
    )
}