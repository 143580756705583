import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const PopupCreditConfirmation = ({ viewPopup, transaction }) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(viewPopup);
    }, [viewPopup]);

    return (
        <>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
                centered
            >
                <Modal.Header className="py-1 " closeButton>
                    <h5
                        className="font-weight-bold mt-1"
                        id="example-custom-modal-styling-title"
                    >
                        Account got CREDITED!!!
                    </h5>
                </Modal.Header>
                <Modal.Body>
                    <div className="card bg-light p-4 text-center">
                        <p>
                            <strong>BDT {transaction.amount}</strong> has been {" "}
                            <>CREDITED </> to your{" "}
                            <strong>{transaction.paymentMethod}</strong>{" "}
                            account.
                        </p>
                        <p>
                            <strong>Make wiser decisions on spending!</strong>
                        </p>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default PopupCreditConfirmation;
