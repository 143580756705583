import { MandatoryAsterisk } from "../FormElements";
import "./style.scss";


const InputWrapper = ({ col, label, addClass, children, mandatory }) => {
    return (
        <div
            className={
                " mb-3 col-md-" + col + " " + addClass
            }
        >
            <div className="input-group">
                <div className="input-group-prepend">
                    <span className="input-group-text">{label} {mandatory && <MandatoryAsterisk/> } </span>
                </div>
                {children}
            </div>
        </div>
    );
};

export default InputWrapper;