import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import ViewDashboard from './ViewDashboard.js/ViewDashboard';
import { useAuth } from '../Hooks/useAuth';
import LoginPage from './LoginPage/LoginPage';
import ViewCredit from './TransactionViews/ViewCredit';
import ViewDebit from './TransactionViews/ViewDebit';
import ViewAllTransactions from './TransactionViews/ViewAllTransactions';
import ViewTransfer from './ViewTransfer/ViewTransfer';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { dataCollectionRef } from '../firebase/fire-app';
import ViewConfiguration from './ViewConfiguration/ViewConfiguration';
import ViewDevelopmentOptions from './ViewDevelopmentOptions/ViewDevelopmentOptions';
import ViewSavings from './TransactionViews/ViewSavings';

const Main = () => {
    const { hasAccess,setHasAccess, setIsAdmin, isAdmin, currentUser } = useAuth();
    const [giveAccess,setGiveAccess] = useState(false);
    const [balanceDetails, loading, error] = useDocumentData(dataCollectionRef.doc("balance"))
    useEffect(() => {
        if (currentUser && currentUser.admin) {
            setHasAccess(true)
            setIsAdmin(true)
            setGiveAccess(true)
        }
    }, [currentUser])
    return (
        <>
            <Router>
                <Routes>
                    {giveAccess && isAdmin && <Route path="/credit" element={<ViewCredit />} />}
                    {giveAccess && isAdmin && <Route path="/" element={<ViewDebit />} />}
                    {giveAccess && isAdmin && <Route path="/savings" element={<ViewSavings />} />}
                    {giveAccess && isAdmin && <Route path="/transactions" element={<ViewAllTransactions />} />}
                    {giveAccess && isAdmin && <Route path="/transfer" element={<ViewTransfer balanceDetails={balanceDetails} />} />}
                    {giveAccess && <Route path="/dashboard" element={<ViewDashboard />} />}
                    {giveAccess && <Route path="/system-configuration" element={<ViewConfiguration />} />}
                    {giveAccess && <Route path="/development-areas" element={<ViewDevelopmentOptions />} />}
                    <Route path="*" element={<LoginPage />} />
                </Routes>
            </Router>

        </>
    );
};

export default Main;