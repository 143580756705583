const firebaseConfig = {
  apiKey: "AIzaSyBz9Skclgj6sAqf2Un8rcmgKbz7Wuoxw6I",
  authDomain: "personal-finance-sjn.firebaseapp.com",
  projectId: "personal-finance-sjn",
  storageBucket: "personal-finance-sjn.appspot.com",
  messagingSenderId: "745605896795",
  appId: "1:745605896795:web:ea09e80e38d8c41f8c7d7e",
  measurementId: "G-XWHZSL3QTT"
};

export default firebaseConfig;